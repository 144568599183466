<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-login card-white">
            <template slot="header">
              <img src="img/card-primary.png" alt="" />
              <h1 class="card-title">登 录</h1>
            </template>

            <div>
              <ValidationProvider
                name="username"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
              <base-input
                required
                v-model="auth.username"
                placeholder="用户名"
                addon-left-icon="tim-icons icon-email-85"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
             </ValidationProvider>

             <ValidationProvider
               name="password"
               rules="required"
               v-slot="{ passed, failed, errors }"
             >
             <base-input
               required
               v-model="auth.password"
               placeholder="密码"
               addon-left-icon="tim-icons icon-lock-circle"
               type="password"
               :error="errors[0]"
               :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
             </base-input>
            </ValidationProvider>
            </div>

            <div slot="footer">
              <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
                登 录
              </base-button>
              <div class="pull-left">
                <h6>
                </h6>
              </div>

              <div class="pull-right">

              </div>
            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("required", {required, message: '不能为空！'});

export default {
  data() {
    return {
      auth:{
        username: "",
        password: ""
      }
    };
  },
  methods: {
    submit() {
      this.$store.dispatch('auth/login', this.auth).then(
        () => {
          this.$router.push('projects');
        },
        error => {
          switch (error.response.status) {
              case 400:
                    if(error.response.data.errors){
                      this.$pop.popError(error.response.status,error.response.data.errors[0].message);
                    }else{
                      this.$pop.popError(error.response.data);
                    }
                    break;
              case 401:
                    this.$pop.popError('登录失败！');
                    break;
          }
        }
      );
    }
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
